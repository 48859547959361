import { createMuiTheme } from '@material-ui/core/styles'
import { heading } from '../../utils/utils'

const fontMain = 'Futura, sans-serif'

const white = '#fff'
const black = '#000'
const primaryMain = '#6ECEB2'
const primaryLight = '#84D5BD'
// const tertiaryMain = '#006747' // gemo for good
// const promoMain = '#ED1C24' // gemo promo

const grey = '#D5D5D5' // Light gray
const greyLight = '#F7F7F7' // White Smoke
const greyMedium = '#646464' // Dim gray
const greyText = '#3D3B3C' // Dark lava

// const manatee = '#9E9E9E' // Manatee
const platinum = '#E1E1E1' // Platinum
// const antiFlashWhite = '#F2F2F2' // Anti-flash white

const imp = '!important'
const hide = {
  display: `none ${imp}`,
}

// const srOnly = {
//     position: 'absolute',
//     width: '1px',
//     height: '1px',
//     overflow: 'hidden',
// }

// const f0 = {
//     fontSize: `10px ${imp}`,
//     lineHeight: 18/10,
// }
const f1 = {
  fontSize: `12px ${imp}`,
  lineHeight: 20 / 12,
}
const f2 = {
  fontSize: `14px ${imp}`,
  lineHeight: 24 / 14,
}
const f3 = {
  fontSize: `16px ${imp}`,
  lineHeight: 28 / 16,
}
const f4 = {
  fontSize: `18px ${imp}`,
  lineHeight: 32 / 18,
}
// const f5 = {
//     fontSize: `20px ${imp}`,
//     lineHeight: 36/20,
// }
// const f6 = {
//     fontSize: `24px ${imp}`,
//     lineHeight: 42/24,
// }
// const f7 = {
//     fontSize: `28px ${imp}`,
//     lineHeight: 48/28,
// }
// const f8 = {
//     fontSize: `32px ${imp}`,
//     lineHeight: 54/32,
// }
// const f9 = {
//     fontSize: `36px ${imp}`,
//     lineHeight: 60/36,
// }
// const f10 = {
//     fontSize: `42px ${imp}`,
//     lineHeight: 68/42,
// }

const bodyS = {
  ...f1,
}
const bodyM = {
  ...f3,
  fontWeight: 400,
  color: greyText,
}
const bodyL = {
  ...f4,
}

// const headingS = {
//     ...heading('16px', '20px', 700, 700, fontMain, fontMain, true)
// }

const headingM = {
  ...heading('18px', '20px', 700, 700, fontMain, fontMain, true),
}

const headingL = {
  ...heading('24px', '32px', 700, 700, fontMain, fontMain, true),
}

// const headingXL = {
//   ...heading('28px', '38px', 700, 700, fontMain, fontMain, true),
// }

const hpTitle = {
  ...headingL,
  color: `${black} ${imp}`,
  textAlign: 'center',
  textTransform: 'uppercase',
  '&:empty:after': {
    ...hide,
  },
}

const btnPrimary = {
  color: `${white} ${imp}`,
  border: `1px solid ${primaryMain} ${imp}`,
  background: `${primaryMain} ${imp}`,
  '&:hover, &:focus, &:active, &.selected': {
    background: `${primaryLight} ${imp}`,
  },
  '&[disabled], &.Mui-disabled': {
    pointerEvent: 'none',
    cursor: 'default',
    color: `${platinum} ${imp}`,
    borderColor: `${platinum} ${imp}`,
    background: `transparent ${imp}`,
    '&:hover, &:focus, &:active, &.selected': {
      borderColor: `${platinum} ${imp}`,
      background: `transparent ${imp}`,
    },
  },
}

const btnOutlinedGreyToGreen = {
  background: `${white} ${imp}`,
  border: `1px solid ${platinum} ${imp}`,
  color: `${grey} ${imp}`,
  padding: `0 10px ${imp}`,
  fontSize: `14px ${imp}`,
  '& .MuiTypography-root': {
    fontSize: `14px ${imp}`,
  },
  '&:hover, &:focus, &:active, &.selected': {
    color: `${white} ${imp}`,
    background: `${primaryMain} ${imp}`,
    borderColor: `${primaryMain} ${imp}`,
    '& .MuiTypography-root': {
      color: `${white} ${imp}`,
    },
  },
}

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  themeFont: {
    main: 'Futura',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: fontMain,
    h1: {
      ...heading('28px', '38px', 700, 700, fontMain, fontMain),
    },
    h2: {
      ...heading('24px', '32px', 700, 700, fontMain, fontMain),
    },
    h3: {
      ...heading('18px', '24px', 700, 700, fontMain, fontMain),
    },
    h4: {
      ...heading('16px', '20px', 700, 700, fontMain, fontMain),
    },
    h5: {
      ...f2,
    },
    h6: {
      ...f3,
    },
    body1: {
      ...bodyM,
    },
    body2: {
      ...bodyM,
    },
    caption: {
      ...bodyS,
    },
    button: {
      fontSize: 16,
      fontWeight: 500,
      fontFamily: fontMain,
    },
  },
  palette: {
    common: {
      black: black,
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
      contrastText: white,
    },
    secondary: {
      main: primaryMain,
      contrastText: white,
    },
    success: {
      main: '#188803',
    },
    error: {
      main: '#c61112',
    },
    warning: {
      main: '#FF8333',
    },
    divider: black,
    primaryTransparent: {
      main: 'rgba(0, 0, 0, .5)',
      light: 'rgba(0, 0, 0, .1)',
    },
    secondaryTransparent: {
      main: 'rgba(118, 118, 118, 1)',
      light: 'rgba(118, 118, 118, .1)',
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#767677',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: grey,
    greyLight: greyLight,
    greyMedium: greyMedium,
    greyText: greyText,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 3,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.label': {
          fontSize: `11px ${imp}`,
          lineHeight: 1,
          margin: `0 ${imp}`,
          padding: `0 ${imp}`,
        },
        '.select': {
          fontSize: `16px ${imp}`,
          padding: `5px 0 ${imp}`,
          background: `${white} ${imp}`,
          border: `0 ${imp}`,
          borderRadius: `0 ${imp}`,
          borderBottom: `2px solid ${platinum} ${imp}`,
          transition: `border-color 0.2s ease-in-out ${imp}`,
          '&::placeholder': {
            color: `${grey} ${imp}`,
            fontSize: `16px ${imp}`,
          },
          '&:focus, &:active': {
            borderColor: `${primaryMain} ${imp}`,
            outline: `0 ${imp}`,
          },
        },
        '.input, .geosuggest__input': {
          fontSize: `16px ${imp}`,
          padding: `6px 0 ${imp}`,
          background: `${white} ${imp}`,
          border: `0 ${imp}`,
          borderRadius: `0 ${imp}`,
          borderBottom: `2px solid ${platinum} ${imp}`,
          transition: `border-color 0.2s ease-in-out ${imp}`,
          '&::placeholder': {
            color: `${grey} ${imp}`,
            fontSize: `16px ${imp}`,
          },
          '&:focus, &:active': {
            borderColor: `${primaryMain} ${imp}`,
            outline: `0 ${imp}`,
          },
          '&.PhoneInput': {
            padding: `0 ${imp}`,
            '& .PhoneInputInput': {
              paddingTop: `7px ${imp}`,
              paddingBottom: `7px ${imp}`,
            },
          },
        },
        '.geosuggest__suggests': {
          border: `1px solid ${platinum} ${imp}`,
          marginTop: `4px ${imp}`,
        },
        '.banner': {
          '@media (max-width: 768px)': {
            '& p': {
              fontSize: `9px ${imp}`,
              lineHeight: `1.25 ${imp}`,
            },
          },
        },
        '.header': {
          '&__logo': {
            '@media (max-width: 768px)': {},
          },
          '&__cta': {
            fontWeight: `500 ${imp}`,
            fontSize: `16px ${imp}`,
            textTransform: `none ${imp}`,
            '& .paragraph-class': {
              fontSize: `16px ${imp}`,
              textTransform: `none ${imp}`,
              fontWeight: `500 ${imp}`,
            },
            '& .MuiSvgIcon-root': {
              width: `32px ${imp}`,
              height: `32px ${imp}`,
              borderRadius: `3px ${imp}`,
              backgroundSize: `auto 18px ${imp}`,
              backgroundRepeat: `no-repeat ${imp}`,
              backgroundPosition: `center center ${imp}`,
              backgroundColor: `transparent ${imp}`,
              transition: `background-color 0.2s ease-in-out ${imp}`,
              '&>path': {
                ...hide,
              },
              '@media (min-width: 1024px)': {
                marginRight: `10px ${imp}`,
                width: `38px ${imp}`,
                height: `38px ${imp}`,
                borderRadius: `50% ${imp}`,
                backgroundColor: `${greyLight} ${imp}`,
              },
            },
            '&:hover, &:focus': {
              '& .MuiSvgIcon-root': {
                backgroundColor: `${primaryMain} ${imp}`,
              },
            },
            '&--account': {
              '& .MuiSvgIcon-root': {
                backgroundImage: `url("/images/gemo/icon_account_off.svg") ${imp}`,
                '.header--transparent &': {
                  backgroundImage: `url("/images/gemo/icon_account_on.svg") ${imp}`,
                },
                '@media (min-width: 1024px)': {
                  '.header--transparent &': {
                    backgroundImage: `url("/images/gemo/icon_account_off.svg") ${imp}`,
                  },
                },
              },
              '&:hover, &:focus': {
                '& .MuiSvgIcon-root': {
                  backgroundImage: `url("/images/gemo/icon_account_on.svg") ${imp}`,
                  '.header--transparent &': {
                    backgroundImage: `url("/images/gemo/icon_account_on.svg") ${imp}`,
                  },
                },
              },
            },
            '&--faq': {
              '& .MuiSvgIcon-root': {
                backgroundImage: `url("/images/gemo/icon_faq_off.svg") ${imp}`,
                '.header--transparent &': {
                  backgroundImage: `url("/images/gemo/icon_faq_on.svg") ${imp}`,
                },
                '@media (min-width: 1024px)': {
                  '.header--transparent &': {
                    backgroundImage: `url("/images/gemo/icon_faq_off.svg") ${imp}`,
                  },
                },
              },
              '&:hover, &:focus': {
                '& .MuiSvgIcon-root': {
                  backgroundImage: `url("/images/gemo/icon_faq_on.svg") ${imp}`,
                  '.header--transparent &': {
                    backgroundImage: `url("/images/gemo/icon_faq_on.svg") ${imp}`,
                  },
                },
              },
              '&.MuiButton-root.Mui-disabled': {
                background: `none ${imp}`,
              },
            },
          },
        },
        '#cart-menu': {
          '& .MuiPaper-root': {},
        },
        '.menu': {
          '&__item': {
            fontSize: `14px !important`,
            '&:after': {
              ...hide,
            },
            '.menu--primary &': {
              fontWeight: `500 ${imp}`,
              fontSize: `14px ${imp}`,
            },
          },
          '&__list': {
            gap: `16px ${imp}`,
          },
        },
        '.basket': {
          marginLeft: `8px ${imp}`,
        },
        '.basket__btn': {
          '&:before': {
            display: 'none',
          },
          '&.MuiButton-root': {
            '& .MuiButton-label': {
              '&>div': {
                padding: '0 4px!important',
                border: '0 !important',
                color: `#000000 !important`,
                background: `#ffffff !important`,
                bottom: '5px !important',
                right: '5px !important',
              },
            },
            '& .basket__icon': {
              height: '38px !important',
              width: '38px !important',
              '& .injected-svg, & img': {
                height: '38px !important',
                width: '38px !important',
              },
            },
            '&:hover, &:focus': {
              '& .basket__icon': {
                background: `url("/images/gemo/icon_cart_on.svg") no-repeat 50% 50% ${imp}`,
                backgroundSize: `contain ${imp}`,
                '& path, & img': {
                  ...hide,
                  visibility: `hidden ${imp}`,
                },
              },
            },
          },
        },
        '.page--home': {},
        '.hero__home': {
          '& .title': {
            ...headingL,
          },
          '& .tagline': {},
          '&-box': {
            maxWidth: `540px ${imp}`,
            padding: `20px ${imp}`,
            color: `${black} ${imp}`,
            background: `${white} ${imp}`,
          },
        },
        '.daterange': {
          '&__btn': {},
        },
        '.hero': {
          '&__toptitle': {},
          '&__title': {},
        },
        '.filter': {
          '&.MuiButtonBase-root': {
            ...btnOutlinedGreyToGreen,
          },
        },
        '.filtersButtons': {
          '&:not(.filtersButtonsActive)': {
            color: `${black} ${imp}`,
          },
        },
        '.shop-claim': {
          '&__desc': {
            '@media (max-width: 1024px)': {
              ...f1,
              marginBottom: `10px ${imp}`,
            },
          },
        },
        '.shipping-infos': {
          '& .MuiBox-root': {},
          '& .MuiTypography-body1': {},
          '& .MuiTypography-body2': {},
        },
        '.cart-shipping--absolute': {
          '& .shipping-infos .MuiBox-root': {},
        },
        '.productCard': {
          '&__title': {},
          '&__price': {},
          '&__soldout': {},
          '&__desc': {},
          '&__add': {},
        },
        '.product': {
          '&__title': {},
          '&__price': {},
        },
        '.packSport': {
          '&__head': {},
          '& .pack__link': {
            '&:hover, &:focus': {},
          },
          '& .pack__title': {
            '& .MuiTypography-root': {},
          },
          '& .pack__desc': {},
        },
        '.pack': {
          '&__title': {
            '& .MuiTypography-root': {},
          },
          '&__products': {
            '&-title': {},
          },
        },
        '.commitment': {
          '&__title': {},
        },
        '.search-page': {
          '& .hero--shop': {
            minHeight: `80px ${imp}`,
            '@media (min-width: 1024px)': {
              minHeight: `200px ${imp}`,
            },
            '& .hero__title': {
              '@media (min-width: 1024px)': {
                marginBottom: `50px ${imp}`,
              },
            },
          },
        },
        '.faq': {
          '&__item': {
            marginBottom: `0 ${imp}`,
            '&:last-child': {
              marginBottom: `20px ${imp}`,
            },
          },
          '&__question': {
            '&.MuiButton-root': {
              ...bodyL,
              marginBottom: `0 ${imp}`,
              borderBottom: `1px solid ${grey} ${imp}`,
              borderRadius: `0 ${imp}`,
              padding: `18px 60px 18px 0 ${imp}`,
              backgroundSize: '1.5rem',
              background:
                'no-repeat right 0.5rem center url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMnJlbSIgd2lkdGg9IjJyZW0iIGZpbGw9IiMyMjIwMjAiICB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGQ9Ik0xOS4wOSwxMWgtNlY1YTEsMSwwLDAsMC0yLDB2NmgtNmExLDEsMCwwLDAsMCwyaDZ2NmExLDEsMCwwLDAsMiwwVjEzaDZhMSwxLDAsMCwwLDAtMloiLz48L3N2Zz4=")',
              '&:hover, &:focus, &[aria-expanded=true]': {
                color: `${black} ${imp}`,
                backgroundColor: `${greyLight} ${imp}`,
              },
              '&[aria-expanded=true]': {
                border: `0 ${imp}`,
                backgroundColor: `${white} ${imp}`,
                backgroundImage:
                  'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMnJlbSIgd2lkdGg9IjJyZW0iIGZpbGw9IiMyMjIwMjAiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTE3LjA5LDEzaC0xMGExLDEsMCwwLDEsMC0yaDEwYTEsMSwwLDAsMSwwLDJaIi8+PC9zdmc+")',
              },
              '@media (max-width: 1024px)': {
                ...bodyS,
              },
            },
          },
          '&__btn': {
            ...hide,
          },
          '&__answer': {
            ...bodyM,
            borderRadius: `0 ${imp}`,
            marginBottom: `0 ${imp}`,
            padding: `8px 0 1rem 0 ${imp}`,
            border: `0 ${imp}`,
            borderBottom: `1px solid ${grey} ${imp}`,
            color: `${greyText} ${imp}`,
            '@media (max-width: 1024px)': {
              ...bodyS,
            },
          },
          '&-page': {
            '&__cat': {
              ...headingM,
              marginTop: `30px !important`,
            },
          },
          '&-content': {
            maxWidth: `1024px ${imp}`,
          },
        },
        '.product__area': {},
        '.testimonial': {
          border: `1px solid ${greyLight} ${imp}`,
          '&__desc': {},
          '&__icon': {
            border: `0 ${imp}`,
            borderRadius: `0 ${imp}`,
            maxWidth: `100px ${imp}`,
          },
        },
        '.hiw': {
          '@media (max-width: 767.95px)': {
            flexDirection: `column ${imp}`,
          },
          '&__icon': {
            '& img': {
              width: `85px ${imp}`,
              height: `auto ${imp}`,
            },
          },
          '&__title, &__desc': {
            textAlign: `center ${imp}`,
          },
        },
        '.hiw, .testimonial': {
          '&__title': {},
        },
        '.discover': {
          '&__card, &__shadow': {
            display: 'flex',
            flexDirection: 'column-reverse',
          },
        },
        '.discover-product': {
          '&__content': {
            left: `0 ${imp}`,
            right: `0 ${imp}`,
            bottom: `0 ${imp}`,
            textAlign: `center ${imp}`,
            color: `${black} ${imp}`,
          },
          '&__title': {
            color: `${black} ${imp}`,
            fontWeight: `700 ${imp}`,
          },
          '&__price': {
            ...hide,
          },
        },
        '.quantitySelector': {
          '&__btn': {},
        },
        '.sizes': {
          '&__btn': {
            ...btnPrimary,
          },
          '&__chip': {},
        },
        '.size-card': {
          '&__soldout': {},
          '&__title': {},
        },
        '.size-modal': {
          '& .MuiDialog-paper': {
            maxWidth: `768px ${imp}`,
            '@media (min-width: 680px)': {
              minWidth: `600px ${imp}`,
            },
          },
          '&__head': {
            backgroundColor: `transparent ${imp}`,
          },
          '&__title': {
            ...bodyS,
          },
          '&__close': {},
          '&__content': {},
        },
        '.section__spacer': {
          padding: `24px 0 ${imp}`,
        },
        '.section-menu': {
          padding: `0 ${imp}`,
          '& .MuiTypography-root': {},
        },
        '.section-infos': {
          '&__image': {},
          '&__content': {
            '& h2': {},
          },
        },
        '.section-hiw': {
          counterReset: 'hiw',
          '& .MuiContainer-root': {
            paddingLeft: `0 ${imp}`,
            paddingRight: `0 ${imp}`,
            //maxWidth: `1360px ${imp}`
          },
          '&__spacing': {
            padding: `32px 0 ${imp}`,
          },
          '& .MuiGrid-item .hiw__title': {
            display: 'list-item',
            counterIncrement: 'hiw',
            listStyle: 'none',
            position: 'relative',
            paddingTop: `90px ${imp}`,
            fontWeight: `400 ${imp}`,
            letterSpacing: `normal ${imp}`,
            '&:before': {
              content: 'counter(hiw)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 48,
              height: 48,
              lineHeight: 1,
              borderRadius: '50%',
              paddingBottom: 4,
              textAlign: 'center',
              background: primaryMain,
              color: white,
              position: 'absolute',
              left: '50%',
              top: 20,
              marginLeft: -24,
              fontWeight: `500 ${imp}`,
            },
            '&:after': {
              '@media (min-width: 768px)': {
                content: '""',
                display: 'block',
                width: 'calc(100% + 24px)',
                height: 1,
                background: primaryMain,
                position: 'absolute',
                top: 44,
                zIndex: -1,
                right: '50%',
              },
            },
          },
          '& .MuiGrid-item:first-child .hiw__title:after': {
            display: 'none',
          },
          '& .MuiGrid-item:nth-child(4) .hiw__title:after': {
            '@media (min-width: 768px)': {
              display: 'none',
            },
          },
          '& .MuiGrid-grid-xs-12': {
            flexBasis: `${100 / 2}% ${imp}`,
            maxWidth: `${100 / 2}% ${imp}`,
          },
          '& .MuiGrid-grid-md-4': {
            flexBasis: `${100 / 2}% ${imp}`,
            maxWidth: `${100 / 2}% ${imp}`,
            '@media (min-width: 768px)': {
              flexBasis: `${100 / 3}% ${imp}`,
              maxWidth: `${100 / 3}% ${imp}`,
            },
          },
        },
        '.section-hiw, .section-faq, .section-testimonials': {
          '&__title': {
            ...hpTitle,
          },
        },
        '.section-faq': {
          '& .MuiContainer-root .MuiContainer-root': {
            padding: `0 ${imp}`,
          },
        },
        '.section-heros': {
          '& .content': {
            '& h2': {
              ...hpTitle,
            },
          },
          '& #section-hero--1': {
            '& p': {
              fontSize: `11px ${imp}`,
            },
          },
        },
        '.section-discover': {
          '&__subtitle': {
            ...hpTitle,
          },
          '& #swiper-products__prev, #swiper-products__next': {
            background: `${white} ${imp}`,
            opacity: `1 ${imp}`,
          },
        },
        '.section-testimonials': {
          '&:before': {
            ...hide,
          },
          '&__title': {
            ...hpTitle,
          },
          '& .MuiContainer-root': {
            paddingLeft: `0 ${imp}`,
            paddingRight: `0 ${imp}`,
            maxWidth: `1360px ${imp}`,
          },
          '& .MuiGrid-grid-md-4': {
            '@media (min-width: 768px)': {
              flexBasis: `${100 / 4}% ${imp}`,
              maxWidth: `${100 / 4}% ${imp}`,
            },
          },
        },
        '.flexContent': {},
        '.funnel': {
          '&__header': {
            '& .MuiTypography-root': {},
          },
          '&__nav': {
            '@media (min-width: 768px)': {},
          },
          '&__box': {
            '& .login-form': {
              '& .account__switch': {
                ...btnOutlinedGreyToGreen,
                display: 'inline-flex',
                padding: `5px 15px ${imp}`,
                color: `${black} ${imp}`,
                border: `2px solid ${primaryMain} ${imp}`,
                background: `${white} ${imp}`,
                borderRadius: `3px ${imp}`,
                '&:hover, &:focus': {
                  color: `${white} ${imp}`,
                  background: `${primaryMain} ${imp}`,
                },
              },
            },
          },
        },
        '.payment-bycard': {
          '@media (min-width: 768px)': {
            width: `600px ${imp}`,
          },
        },
        '.footer': {
          '&__logo': {
            height: `44px ${imp}`,
          },
          '&__top': {
            background: `${white} ${imp}`,
            '& .MuiTypography-root': {
              color: `${black} ${imp}`,
              fontWeight: `700 ${imp}`,
            },
            '& .MuiGrid-container': {
              justifyContent: 'flex-end',
              '@media (max-width: 1024px)': {
                justifyContent: 'center',
              },
              '&> .MuiGrid-item': {
                maxWidth: 'none',
                flexBasis: 'auto',
                '@media (max-width: 1024px)': {
                  '&>a': {
                    marginTop: 0,
                  },
                },
              },
            },
          },
          '&__menu': {
            '& a': {
              ...f2,
            },
            '@media (max-width: 1024px)': {
              display: `flex ${imp}`,
              flexDirection: `column ${imp}`,
              '& a': {
                marginLeft: `0 ${imp}`,
              },
            },
            '@media (min-width: 1024px)': {
              flex: `inherit ${imp}`,
            },
          },
          '& .MuiButton-outlined': {
            ...btnPrimary,
          },
        },
        '.page': {
          '&__hero': {
            color: 'white',
            background: `${primaryLight} ${imp}`,
            textAlign: 'center',
            maxWidth: '100% !important',
            paddingBottom: '35px !important',
            marginBottom: '35px !important',
            '& .MuiTypography-root': {},
          },
          '&__container': {
            '& .page-wysiwyg': {
              '& *': {},
            },
          },
          '& #section-text-0': {
            padding: `20px ${imp}`,
            marginBottom: `40px ${imp}`,
            '& h1': {
              marginBottom: `0 ${imp}`,
            },
          },
          '& .section-columns': {
            '& .MuiGrid-item': {
              '& .block-img>img': {
                maxWidth: 120,
              },
            },
          },
        },
        '.account, .contact-page, .checkout': {
          '& + .footer': {},
        },
        '.account': {
          '&-card': {
            '& .account__switch': {
              ...btnOutlinedGreyToGreen,
              display: 'inline-flex',
              padding: `5px 15px ${imp}`,
              color: `${black} ${imp}`,
              border: `2px solid ${primaryMain} ${imp}`,
              background: `${white} ${imp}`,
              borderRadius: `3px ${imp}`,
              '&:hover, &:focus': {
                color: `${white} ${imp}`,
                background: `${primaryMain} ${imp}`,
              },
            },
          },
          '&>.MuiContainer-root': {
            maxWidth: `1280px ${imp}`,
          },
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 3,
        boxShadow: 'none',
        '&:focus': {},
        '&.Mui-disabled': {
          color: `${greyMedium} ${imp}`,
          backgroundColor: `${platinum} ${imp}`,
        },
      },
      label: {},
      sizeSmall: {
        padding: `3px 10px`,
      },
      sizeLarge: {
        padding: `6px 20px`,
      },
      contained: {
        padding: `7px 20px`,
        '&:hover, &:focus, &:active, &.selected': {},
        '&[disabled]': {},
      },
      containedPrimary: {
        '&:hover, &:focus, &:active, &.selected': {
          backgroundColor: `${primaryLight} ${imp}`,
        },
      },
      containedSecondary: {
        '&:hover, &:focus, &:active, &.selected': {
          backgroundColor: `${primaryLight} ${imp}`,
        },
      },
      outlined: {
        '&:hover, &:focus, &:active, &.selected': {},
      },
      outlinedPrimary: {
        color: `${black} ${imp}`,
        backgroundColor: `${white} ${imp}`,
        border: `2px solid ${primaryMain} ${imp}`,
        '&:hover, &:focus, &:active, &.selected': {
          color: `${white} ${imp}`,
          backgroundColor: `${primaryMain} ${imp}`,
        },
      },
      outlinedSecondary: {
        color: `${black} ${imp}`,
        backgroundColor: `transparent ${imp}`,
        border: `2px solid ${black} ${imp}`,
        '&:hover, &:focus, &:active, &.selected': {
          color: `${white} ${imp}`,
          backgroundColor: `${black} ${imp}`,
        },
      },
      outlinedSizeSmall: {},
    },
    MuiTab: {
      root: {
        borderRadius: `2px ${imp}`,
        padding: `5px 15px ${imp}`,
      },
    },
    MuiPaper: {
      root: {},
      rounded: {
        borderRadius: 0,
      },
    },
    MuiSelect: {
      root: {
        color: '#2933E7',
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiChip: {
      root: {},
      label: {},
      sizeSmall: {},
      labelSmall: {},
      colorPrimary: {},
      colorSecondary: {},
    },
  },
})

export default theme
